import { App } from "vue";
import VueApexCharts from "vue3-apexcharts";

/**
 * Initialize VueApexChart component
 * @param app vue instance
 */
export function initApexCharts(app: App<Element>) {
  app.component('apexchart', VueApexCharts); // Fix apex require 2 arguments but got one
}
