/* eslint-disable */
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
export interface User {
  _id: string;
  mother: {
    firstName: string;
    lastName: string;
    phone: string;
    job: string;
  };
  father: {
    firstName: string;
    lastName: string;
    phone: string;
    job: string;
  };
  status: string;
  students: Array<Student>;
  buildingDB: string;
  email: string;
  password: string;
  token: string;
  address: string;
  photo: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  student: Student;
  isAuthenticated: boolean;
}
export interface Student {
  activities: Array<string>;
  _id: string;
  classRoom: Object;
  schoolarYearsHistory: Object;
  photo: string;
}

export interface SchoolarYear {
  _id: string;
  name?: string;
}

export interface ServerConfig {
  base_url?: string;
  dbName: string;
  building: object;
}

const isDateInRange = (
  date: Date,
  startMonth: Number,
  endMonth: Number
): Boolean => {
  let month = date.getMonth() + 1;
  return startMonth <= month && month <= endMonth;
};

function getCurrentTrimester(): Number {
  const now = new Date();
  if (isDateInRange(now, 9, 12)) return 1;
  else if (isDateInRange(now, 1, 3)) return 2;
  return 3;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  student = {} as Student;
  scholarYear = {} as SchoolarYear;
  serverConfig = {} as ServerConfig;
  trimester = getCurrentTrimester() as Number;
  isAuthenticated = !!JwtService.getToken();
  buildingConfig = {
    teacherParentChat: true,
  }
  /**
   * Get base_url
   * @returns string
   */
  get serverConfigUrl(): ServerConfig {
    return this.serverConfig;
  }
  /**
   * Get trimester
   * @returns Number
   */
  get currentTrimester(): Number {
    return this.trimester;
  }
  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }
  /**
   * Get current SchoolarYear object
   * @returns SchoolarYear
   */
  get currentYear(): SchoolarYear {
    return this.scholarYear;
  }
  /**
   * Get current Student object
   * @returns Student
   */
  get currentStudent(): Student {
    return this.student;
  }
  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  get buildingConfigValue(): object {
    return this.buildingConfig
  }
  @Mutation
  [Mutations.SET_SERVER_CONFIG](serverConfig) {
    this.serverConfig = serverConfig;
  }
  @Mutation
  [Mutations.SET_TRIMESTER](trimester) {
    this.trimester = trimester;
  }
  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    console.log("set_auth", user);
    this.isAuthenticated = true;
    this.user = user.info;
    if (user.buildingConfig) {
      console.log(user.buildingConfig)
      this.buildingConfig = user.buildingConfig
    }
    let activeStudent = user.info.students[0];
    if (window.localStorage.getItem("activeStudent")) {
      activeStudent = this.user.students.find(
        (i) => i._id === window.localStorage.getItem("activeStudent")
      );
    }

    this.student = activeStudent;

    console.log("user", user);
    this.errors = [];
    JwtService.saveToken(user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
    this.student = user.info.students[0];
  }

  @Mutation
  [Mutations.SET_CURRETN_YEAR](year) {
    this.scholarYear = year;
  }

  @Mutation
  [Mutations.SET_STUDENT](student) {
    this.student = student;
    window.localStorage.setItem("activeStudent", student._id);
  }
  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.student = {} as Student;
    this.errors = [];
    JwtService.destroyToken();
    JwtService.destroySchoolarYear();
    JwtService.destroyDb();
    ApiService.clearHeader();
    router.push("/sign-in");
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/parents/login", credentials)
        .then((data) => {
          window.localStorage.setItem(
            "activeStudent",
            data.data.info.students[0]._id
          );
          let schoolarYearsHistory = Object.keys(
            data.data.info.students[0].schoolarYearsHistory
          );
          window.localStorage.setItem(
            "activeSchoolarYear",
            schoolarYearsHistory[schoolarYearsHistory.length - 1]
          );
          this.scholarYear._id =
            schoolarYearsHistory[schoolarYearsHistory.length - 1];
          this.scholarYear.name = "--";
          window.localStorage.setItem("parentId", data.data.info._id);
          this.context.commit(Mutations.SET_AUTH, data.data);
          resolve();
        })
        .catch((response) => {
          console.log(response);
          this.context.commit(
            Mutations.SET_ERROR,
            "username or password incorrect"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/parents/forgotpassword", payload)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          this.context.commit(Mutations.SET_ERROR, "error");
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("/parents/verify")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(() => {
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
