import { App } from "vue";
import vSelect from 'vue-select'



/**
 * Initialize Inline-Svg component
 * @param app vue instance
 */
export function initVueSelect(app: App<Element>) {
    app.component('v-select', vSelect)
}
